// Your variable overrides
$theme-colors: (
  'primary': #0f5d5e,
  'success': #c3d42c,
  'danger': #d44b40
);

// Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

.modal80w {
  width: 75%;
  max-width: none !important;
}

.leftpaddingless {
  padding-left: 0 !important;
}

.paddingless {
  padding: 0 !important;
}
